import getAuthToken from '@/utils/authToken'
import axios, { AxiosInstance } from 'axios'

const createAxiosInstance = (
  lang: string = 'ka',
  options?: { multipart?: boolean }
) => {
  const axiosInstance: AxiosInstance = axios.create({
    baseURL: process.env.NEXT_PUBLIC_API_URL,
    timeout: options?.multipart ? 5000 : 3000,
    withCredentials: false
  })

  axiosInstance.interceptors.request.use(
    async (config) => {
      const serverToken = await getAuthToken()
      const token = serverToken

      if (token) {
        config.headers['Authorization'] = `Bearer ${token}`
      }

      config.headers['Accept-Language'] = lang
      config.headers['x-lang'] = lang

      if (options?.multipart) {
        config.headers['content-type'] =
          'multipart/form-data; boundary=----WebKitFormBoundaryqTqJIxvkWFYqvP5s'
      }

      return config
    },
    (error) => {
      return Promise.reject(error)
    }
  )

  return axiosInstance
}

export default createAxiosInstance
