import classNames from 'classnames'
import {
  createLocalizedPathnamesNavigation,
  Pathnames
} from 'next-intl/navigation'
import { PropsWithChildren } from 'react'

export const { Link: NextIntlLink } = createLocalizedPathnamesNavigation({
  locales: ['ka', 'en'],
  pathnames: {},
  localePrefix: 'always'
})

export type LinkProps = PropsWithChildren<
  {
    className?: string
    styled?: boolean
    scroll?: boolean
    onClick?: () => void
  } & Pathnames<never>
>

const Link = ({
  children,
  href,
  className,
  onClick,
  locale,
  target,
  disabled,
  styled = true,
  scroll = true // do not keep
}: LinkProps) => {
  return (
    <NextIntlLink
      className={classNames(
        {
          'pointer-events-none cursor-default': disabled,
          'text-sm text-black transition-all duration-300 ease-in-out hover:text-yellow':
            styled
        },
        className
      )}
      onClick={onClick}
      scroll={scroll}
      target={target as string}
      href={href as never}
      locale={locale as string}
    >
      <>{children}</>
    </NextIntlLink>
  )
}

export default Link
