'use client'

import { User } from '@/types/user'
import { PropsWithChildren, createContext, useContext, useState } from 'react'

export interface CurrentUserContextType {
  currentUser: User | null | undefined
  setCurrentUser: (data: User | null) => void
  accessToken: string | null | undefined
  setAccessToken: (token: string | null) => void
  fetchCurrentUser?: () => void
}

const CurrentUserContext = createContext<CurrentUserContextType | undefined>({
  currentUser: null,
  setCurrentUser: () => null,
  accessToken: null,
  setAccessToken: () => null
})

export const useCurrentUserContext = () => {
  const context = useContext(CurrentUserContext)

  if (!context) {
    throw new Error(
      'useCurrentUserContext must be used within a CurrentUserProvider'
    )
  }
  return context
}

export const UserProvider = ({
  user,
  token,
  children
}: PropsWithChildren<{ user?: User | null; token?: string | null }>) => {
  const [currentUser, setCurrentUser] = useState<User | undefined | null>(user)
  const [accessToken, setAccessToken] = useState<string | undefined | null>(
    token
  )

  const value: CurrentUserContextType = {
    currentUser,
    accessToken,
    setCurrentUser,
    setAccessToken
  }

  return (
    <CurrentUserContext.Provider value={value}>
      {children}
    </CurrentUserContext.Provider>
  )
}
