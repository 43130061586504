import { Brand } from '@/types/brand'
import { Resource } from '@/types/resource'
import { User } from '@/types/user'
import * as _ from 'lodash'

// Define a generic Translation type that can have multiple fields
type Translation = { [key: string]: string }

// Define a generic type for TranslatableField
type TranslatableField = { translations: Translation[] }

// Define a generic Record type that can represent any structure
type RecordType = {
  [key: string]: any
}

// Define the Fields type, which can contain either strings or nested field structures
type Fields = (string | [string, string[]])[]

export const formatToPrice = (price: number) => {
  return (price / 100).toLocaleString('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  })
}

export const formatToNumber = (number: number) => {
  return number.toLocaleString('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 1
  })
}

export function fileToBase64(file: File) {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve((reader.result as string).split(',')[1])
    reader.onerror = (error) => reject(error)
  })
}

export const getUserInitials = ({ first_name, last_name }: User) => {
  return `${first_name[0]}${last_name[0]}`
}

export const translateRecords = <T extends RecordType>(
  data: T,
  fields: Fields,
  lang: 'en' | 'ka' = 'ka'
): T => {
  const isSingleRecord = !Array.isArray(data)
  const actualData = isSingleRecord ? [data] : data
  const translatedData = actualData.map((record) => {
    const translatedRecord = _.cloneDeep(record)

    fields.forEach((field) => {
      if (Array.isArray(field)) {
        // Handle nested fields (e.g., ['status', ['name', 'description']])
        const [parentField, subFields] = field

        // Get the first translation for the parent field
        const parentTranslations: Translation[] = _.get(
          translatedRecord,
          `${parentField}.translations`,
          []
        )
        const parentTranslation = parentTranslations?.find(
          ({ language }) => language === lang
        )

        if (parentTranslation) {
          // Replace the parent field's translations with the single translation for all specified subfields
          subFields.forEach((subField) => {
            if (parentTranslation[subField]) {
              _.set(
                translatedRecord,
                `${parentField}.${subField}`,
                parentTranslation[subField]
              )
            }
          })
        }

        // Clean up the translations array
        _.unset(translatedRecord, `${parentField}.translations`)
      } else {
        // Handle root-level fields (e.g., 'name')
        const translations: Translation[] = _.get(
          translatedRecord,
          `translations`,
          []
        )
        const translation = translations?.find(
          ({ language }) => language === lang
        )

        if (translation) {
          // Replace translatable fields at the root level
          _.set(translatedRecord, field, translation[field])
        }

        // Clean up the translations array at the root level
        _.unset(translatedRecord, `translations`)
      }
    })

    return translatedRecord
  })

  return isSingleRecord ? translatedData[0] : translatedData
}

export const resourceToOption = (resources: Resource[] = []) =>
  resources.map(({ id: value, name: label }) => ({ value, label }))

export const getBrandLogoUrl = (brand: Brand & { assets: any[] }) => {
  const logoUrl = brand?.assets?.find(({ type }) => type === 'logo')?.src

  return logoUrl ?? null
}

export const getProductThumbnail = (assets: any[]) => {
  return (
    assets.find(({ type, primary }) => type === 'image' && primary)?.src ?? ''
  )
}

export const formatBytes = (bytes: number, decimals = 2): string => {
  if (bytes === 0) return '0 Bytes'

  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
  const k = 1024 // base for KB/MB/GB

  const i = Math.floor(Math.log(bytes) / Math.log(k))
  const value = bytes / Math.pow(k, i)

  return `${_.round(value, decimals)} ${sizes[i]}`
}

export const getCategoryTree = (category: any): any[] => {
  const categoryTree: any[] = []

  // Traverse up the tree
  let currentCategory: any | null = category

  while (currentCategory) {
    const categoryNode = {
      url: `/category/${currentCategory.slug}`,
      name: currentCategory.name
    }

    // Prepend each category to maintain parent-to-child order
    categoryTree.unshift(categoryNode)

    // Move up to the parent category
    currentCategory = currentCategory.parent || null
  }

  return categoryTree
}

export const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  })
}

export const removeHTMLTags = (str: string) =>
  str?.replace(/<\/?[^>]+(>|$)/g, '')
