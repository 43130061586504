import {
  CurrentUserContextType,
  useCurrentUserContext
} from '@/context/CurrentUserContext'
import createAxiosInstance from '@/services/api'
import { SingleResponse } from '@/types/api'
import { User } from '@/types/user'

const useCurrentUser = (): CurrentUserContextType => {
  const axios = createAxiosInstance()
  const { currentUser, setCurrentUser, accessToken, setAccessToken } =
    useCurrentUserContext()

  const fetchCurrentUser = async () => {
    const response = await axios.get<SingleResponse<User>>('/profile')

    if (response.status === 200) {
      setCurrentUser(response.data.data)
    }
  }

  return {
    currentUser,
    setCurrentUser,
    accessToken,
    setAccessToken,
    fetchCurrentUser
  }
}

export default useCurrentUser
